import applications from './endpoints/applications'
import attachments from './endpoints/attachments'
import bcapd from './endpoints/bcapd'
import bordereau from './endpoints/bordereau'
import comments from './endpoints/comments'
import documents from './endpoints/documents'
import external from './endpoints/external'
import policies from './endpoints/policies'
import programs from './endpoints/programs'
import rater from './endpoints/rater'
import tasklist from './endpoints/tasklist'
import tasks from './endpoints/tasks'

export const api = {
  applications,
  attachments,
  bcapd,
  bordereau,
  comments,
  documents,
  external,
  policies,
  programs,
  rater,
  tasklist,
  tasks,
}
